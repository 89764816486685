import React from "react";
import { useHowler } from "./useHowler";

const AudioContext = React.createContext<ReturnType<typeof useHowler>>(
  {} as any
);

export function useAudio() {
  const context = React.useContext(AudioContext);

  if (!context) {
    throw new Error("useAudioContext must be used within a AudioProvider");
  }

  return context;
}

export function AudioProvider({ children }: { children: React.ReactNode }) {
  const context = useHowler();

  return (
    <AudioContext.Provider value={context}>{children}</AudioContext.Provider>
  );
}
