import dynamic from "next/dynamic";
import * as React from "react";

import { HandlerNames, useBridge } from "@/features/bridge/useBridge";

const Devtools = dynamic(() => import("@/components/devtools/Devtools"));

export const DEVTOOLS_PREFIX = "devtools";

// we want it enabled by default everywhere but production and we also want
// to support the dev tools in production (to make us more productive triaging production issues).
// you can enable the DevTools via localStorage or the query string.

// use a dynamic import so the dev-tools code isn't bundled with the regular
// app code so we don't worry about bundle size.
export function useLoadDevTools() {
  const { bridge } = useBridge();
  React.useEffect(() => {
    bridge?.registerHandler(HandlerNames.gotDeviceShaken, () => {
      window?.localStorage.setItem(DEVTOOLS_PREFIX, "true");
      window?.location.reload();
    });
  }, [bridge]);

  if (typeof window === "undefined") {
    return false;
  }

  // this allows you to explicitly disable it in development for example
  const explicitlyDisabled =
    window?.location.search.includes(`${DEVTOOLS_PREFIX}=false`) ||
    window?.localStorage.getItem(DEVTOOLS_PREFIX) === "false";

  const explicitlyEnabled =
    window?.location.search.includes(`${DEVTOOLS_PREFIX}=true`) ||
    window?.localStorage.getItem(DEVTOOLS_PREFIX) === "true";

  const showDevTools =
    !explicitlyDisabled &&
    (process.env.NODE_ENV === "development" || explicitlyEnabled);

  return showDevTools;
}

export function DynamicDevtools() {
  const shouldShowDevTools = useLoadDevTools();

  if (!shouldShowDevTools) {
    return null;
  }

  return <Devtools />;
}
