import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../root-store";

export type AudioState = {
  muted: boolean;
};

const initialState: AudioState = {
  muted: false,
};

const audioSlice = createSlice({
  name: "audio",
  initialState,
  reducers: {
    toggleMute: (state, action: PayloadAction<undefined | boolean>) => {
      const updatedMuteValue =
        typeof action.payload === "boolean" ? action.payload : !state.muted;

      state.muted = updatedMuteValue;
    },
  },
});

export const { actions: audioActions, reducer: audioReducer } = audioSlice;

export const audioSelectors = {
  muted: (state: RootState) => state.audio.muted,
};
