import { extendTheme, ThemeConfig } from "@chakra-ui/react";
import * as foundations from "./foundations";
import * as components from "./components";
import { globalStyles } from "./global";

const config: ThemeConfig = {
  initialColorMode: "dark",
  useSystemColorMode: false,
  cssVarPrefix: "gaze",
};

/**
 * Gaze Theme
 *
 * Extends from chakra-ui default theme.
 * See https://chakra-ui.com/docs/theming/theme for documentation.
 * See https://github.com/chakra-ui/chakra-ui/tree/main/packages/theme for default theme values.
 *
 * @note Some items must be spread to avoid overriding defaults.
 */
export const theme = extendTheme({
  config,
  ...foundations,
  components: { ...components },

  styles: { global: globalStyles },
});

export type Theme = typeof theme;
