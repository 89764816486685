import React from "react";

export function useServiceWorker() {
  React.useEffect(() => {
    const onload = () => {
      navigator.serviceWorker
        .register("/sw.js")
        .then((registration) => {
          console.info("[ServiceWorker] registered: ", registration);
        })
        .catch((registrationError) => {
          console.error(
            "[ServiceWorker] registration failed: ",
            registrationError
          );
        });
    };

    if ("serviceWorker" in navigator) {
      window.addEventListener("load", onload);

      return () => {
        window.removeEventListener("load", onload);
      };
    }
  }, []);
}
