import { RootState } from "./root-store";
import { API_PATH } from "@/api/environment";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const rootApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: API_PATH,
    prepareHeaders: async (headers, { getState }) => {
      const { auth } = getState() as RootState;
      if (auth.token) {
        headers.set("Authorization", `Bearer ${auth.token.accessToken}`);
      }

      if (!headers.has("Content-Type")) {
        headers.set("Content-Type", "application/json");
      }

      if (!headers.has("Accept")) {
        headers.set("Accept", "application/json");
      }

      return headers;
    },
  }),

  endpoints: () => ({}),
  tagTypes: ["Token", "MySlots", "OpenSlots"],
});
