import { Colors } from "@chakra-ui/theme";

const status: Colors = {
  error: "#bb2124",
  success: "#77a969",
  warning: "#f0ad4e",
  info: "#5bc0de",
};

const brand = {
  brandPurple: "#7d5f8c",
  brandMagenta: "#EB367F",
  brandPink: "#FF0080",
  brandYellow: "#FFF500",
  brandBlue: "#85ace0",
};

const elements = {
  black: "#000",
  white: "#FFF",
  muted: "#6c757d",
};

/** Branded Colors */
export const colors: Colors = {
  ...brand,
  ...elements,
  ...status,
} as const;
