import * as React from "react";
import { useToast, UseToastOptions } from "@chakra-ui/react";

import { isDevelopment } from "../../api/environment";
import { useAudio } from "../audio/AudioContext";
import { SoundMap } from "../audio/Sound";
import {
  GazeEventHandler,
  GazeEventHandlerOptions,
  GazeEvent,
  GazeEventName,
} from "./GazeEvent";

/**
 * # ControllerContext
 * @packageDescription
 *
 * The idea is to provide a way for components to send/recieve events from the controller.
 *
 * ## `on`
 *
 * When a child component mounts, it has the ability to subscribe to events.
 * This is done by using the `on` function.
 *
 * The `on` function takes a string as the event name and a function as the event handler.
 * It returns a function that can be used to unsubscribe from the event.
 * This function should be called whenever a component unmounts.
 *
 * ## `emit`
 *
 * The same thing applies to sending events to the controller.
 * This is done by using the `emit` function.
 *
 * The `emit` function takes a string as the event name and an object as the event payload.
 * It also takes an optional `ui` property that can be used to control specific UI elements (sounds & toast).
 *
 * The `on` and `emit` functions are both bound to the `useControllerActions` hook.
 *
 * The `useControllerActions` hook will return the `on` and `emit` functions and the core context itself should not be used directly.
 * This hook also automatically unsubscribes from events when the component unmounts.
 *
 * e.g. { init: { 'callback1': {callback: (event) => void, options: {once: false }}}}
 */

type ControllerStateMap = Partial<{
  [key in GazeEventName]: {
    [handlerId: string]: {
      callback: GazeEventHandler;
      options?: GazeEventHandlerOptions;
    };
  };
}>;

export type ControllerStateContext =
  null | React.MutableRefObject<ControllerStateMap>;

export type ControllerActionsContext = {
  on: (
    eventName: GazeEventName,
    callback: GazeEventHandler<typeof eventName>,
    options?: GazeEventHandlerOptions
  ) => () => void;

  off: (eventName: GazeEventName, callbackId: string) => void;

  emit: (
    /** Name of the event to fire */
    eventName: GazeEventName,

    /** Extends default event data */
    payload?: Partial<Omit<GazeEvent<typeof eventName>, "name">>,

    /** Custom UI Element handlers */
    config?: {
      /** Toast Options for dispalying messages to the users */
      toast?: UseToastOptions;
      onToast?: (toast: UseToastOptions) => void;

      /** Sound to play when the event is fired */
      sound?: { id: string };
      onSound?: (sound: SoundMap[string]) => void;

      /** Delay in milliseconds before the callback is fired */
      delay?: number;
    }
  ) => Promise<GazeEvent<typeof eventName>>;
};

export const ControllerStateContext =
  React.createContext<ControllerStateContext>(null);

export const ControllerActionsContext =
  React.createContext<ControllerActionsContext>({
    on: () => {
      throw new Error("[Controller] No on function provided");
    },

    off: () => {
      throw new Error("[Controller] No off function provided");
    },

    emit: () => {
      throw new Error("[Controller] No emit function provided");
    },
  });

export function ControllerProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const state = React.useRef<ControllerStateMap>({});
  const toast = useToast();
  const { playSound } = useAudio();

  const actions = React.useMemo<ControllerActionsContext>(
    () => ({
      on: (eventName, callback, options) => {
        // TODO: better id generation
        const callbackId = `${Date.now()}-${Math.random()
          .toString(36)
          .slice(2, 9)}`;

        const eventExists = Boolean(state.current[eventName]);
        const eventHandlerMap = eventExists
          ? state.current[eventName]
          : undefined;

        const callbackData = { callback, options };

        if (eventHandlerMap) {
          // Add the callback to the event handler map
          eventHandlerMap[callbackId] = callbackData;
        } else {
          // Set the 1st (default) event map
          state.current[eventName] = { [callbackId]: callbackData };
        }

        return () => {
          actions.off(eventName, callbackId);
        };
      },

      off: (eventName, callbackId) => {
        const eventExists = Boolean(state.current[eventName]);
        const eventHandlerMap = eventExists
          ? state.current[eventName]
          : undefined;

        const callbackExists = Boolean(
          eventHandlerMap && eventHandlerMap[callbackId]
        );

        if (callbackExists) {
          delete eventHandlerMap?.[callbackId];
        }

        if (eventHandlerMap && Object.keys(eventHandlerMap).length === 0) {
          delete state.current[eventName];
        }
      },

      emit: async (eventName, payload = {}, config = {}) => {
        const {
          toast: toastConfig,
          onToast,

          sound: soundConfig,
          onSound,

          delay = false,
        } = config;

        const eventHandlers = Object.entries(state?.current[eventName] || {});

        const event: GazeEvent<typeof eventName> = {
          name: eventName,
          timestamp: new Date().toISOString(),
          ui: {}, // set when the ui element event is fired
          ...payload,
        };

        if (isDevelopment) {
          console.info("[Controller]", `Emitting event: ${eventName}`, event);
        }

        if (delay) {
          await new Promise((resolve) => setTimeout(resolve, delay));
        }

        if (toastConfig) {
          const options: UseToastOptions = {
            isClosable: true,
            duration: 3500,
            position: "top-right",
            status: "info",
            variant: "left-accent",
            ...toastConfig,
          };

          const toastId = toast(options);
          event.ui.toastId = toastId;
          toastId && onToast?.({ ...toastConfig, id: toastId });
        }

        if (soundConfig) {
          const sound = await playSound(soundConfig.id);
          event.ui.soundId = soundConfig.id;

          sound && onSound?.(sound);
        }

        const promises = eventHandlers.map(
          async ([callbackId, { callback, options }]) => {
            const result = await callback(event);

            if (options?.once) {
              actions.off(eventName, callbackId);
            }

            return result;
          }
        );

        await Promise.all(promises);

        return event;
      },
    }),
    [toast, playSound]
  );

  return (
    <ControllerStateContext.Provider value={state}>
      <ControllerActionsContext.Provider value={actions}>
        {children}
      </ControllerActionsContext.Provider>
    </ControllerStateContext.Provider>
  );
}

export function useControllerActions() {
  const context = React.useContext(ControllerActionsContext);

  if (!context) {
    throw new Error(`No ControllerStateContext provided`);
  }

  return context;
}

/**
 * Get access to the controller state. Mostly used for debugging.
 */
export function useControllerState() {
  const context = React.useContext(ControllerStateContext);

  if (!context) {
    throw new Error(`No ControllerStateContext provided`);
  }

  return context;
}
