import { Box, Text, useToast } from "@chakra-ui/react";
import { Howl as HHowl, Howler as HHowler } from "howler";
import * as React from "react";

import {
  HandlerConfigs,
  HandlerNames,
  useBridge,
} from "@/features/bridge/useBridge";
import { audioSelectors } from "@/services/store/slices/AudioStore";
import { useRootSelector } from "@/services/store/useStore";
import { localSprites } from "./localSprites";
import { Sound, SoundMap } from "./Sound";

/**
 * We use howler to handle the audio for our app.
 *
 * Specifically, for narration, micro-interactions, and other audio that is static
 *   within the app.
 *
 * Howler works by providing three levels of access to their API:
 * - Global - a singleton {@link Howler} that is accessible from anywhere in the app.
 * - Group - a group of sounds that can be played individually and controlled as a group.
 *  - To create a group of sounds, we need to pass a single audio file.
 *      Use the sprite option to id the sounds when creating them.
 * - Sound - a single {@link Howl} class that can be played and controlled.
 *  - To create a sound, we need to pass in a single audio file.
 *
 * @example Global
 * ```ts
 * import { Howl, Howler } from 'howler';
 *
 * if(typeof window !== 'undefined') {
 *  howler.play();
 * }
 * ```
 *
 * @example Group (sprite)
 * ```ts
 * const sound = isClientSide && new Howl({
 *  src: ['/audio/narration/sprite.mp3'],
 *  sprite: {
 *   1: [0, 3000],
 *   2: [5000, 6000],
 *  }
 * });
 *
 * sound.play('1');
 * ```
 *
 * @exmple Sound
 * ```ts
 * const sound = isClientSide && new Howl({ src: ['/audio/narration/1.mp3'] });
 *
 * sound.play();
 * ```
 */
export function useHowler() {
  const isMuted = useRootSelector(audioSelectors.muted);

  const toast = useToast();
  const { bridge } = useBridge();

  const sounds = React.useRef<SoundMap>({});

  const unloadTrack = React.useCallback(
    (sound: Pick<Sound, "id">) => {
      const track = sounds.current[sound.id];
      const bridgeSound = "playmode" in track && track;
      const jsSound = "howl" in track && track;

      if (!track) {
        return;
      } else if (bridgeSound) {
        bridge?.callHandler(HandlerNames.stopAudio, {}, () => {});
      } else if (jsSound) {
        track.howl?.stop();
        track.howl?.unload();
        track.howl = undefined;
      }

      delete sounds.current[sound.id];
    },

    [bridge]
  );

  const loadTrack = React.useCallback((sound: Sound) => {
    const jsTrack = "url" in sound ? sound : undefined;
    const bridgeTrack = "playmode" in sound ? sound : undefined;

    if (bridgeTrack) {
      sounds.current[bridgeTrack.id] = bridgeTrack;
      return bridgeTrack; // Bridge tracks do not need to be loaded.
    } else if (jsTrack) {
      const existingTrack = sounds.current[jsTrack.id];
      const howlExists =
        existingTrack && "howl" in existingTrack && existingTrack;
      if (existingTrack && howlExists) {
        return existingTrack;
      }

      const howl = new HHowl({
        src: [jsTrack.url],
        onplayerror: () => {
          howl.on("unlock", howl.play); // Try to play again after howler's unlock mechanism
        },
      });

      const track: SoundMap[string] = Object.assign({}, sound, { howl });

      sounds.current[jsTrack.id] = track;
      return track;
    }

    return undefined;
  }, []);

  const closeCaption = React.useCallback(
    (id: string) => {
      const track = sounds.current[id];

      if (track.captionId) {
        toast.close(track.captionId);
      }

      track.captionId = undefined;
    },
    [toast]
  );

  const openCaption = React.useCallback(
    (id: string) => {
      const track = sounds.current[id];

      if (track.caption) {
        const bridgeSound = "playmode" in track ? track : undefined;
        const jsSound = "howl" in track ? track : undefined;

        if (bridgeSound) {
          return; // Bridge audio currently doesn't support captions
        } else if (jsSound) {
          jsSound.howl?.once("play", () => {
            jsSound.captionId = toast({
              position: "bottom",

              render: ({ id: toastId }) => (
                <Box
                  key={toastId}
                  bg="blackAlpha.900"
                  p="2.5%"
                  maxWidth="xs"
                  color="whiteAlpha.900"
                  textAlign="center"
                  rounded="lg"
                  shadow="lg"
                >
                  <Text fontSize="xs">{jsSound.caption}</Text>
                </Box>
              ),
            });
          });

          jsSound.howl?.once("end", () => closeCaption(id));
          jsSound.howl?.once("stop", () => closeCaption(id));
          jsSound.howl?.once("pause", () => closeCaption(id));
        }
      }
    },
    [closeCaption, toast]
  );

  const playSound = React.useCallback(
    async (
      id: string,
      config: {
        loop?: boolean;
        volume?: number;
        delay?: number;
        playmode?: HandlerConfigs["playAudio"]["params"]["playmode"];
      } = {}
    ) => {
      let track = sounds.current[id];
      const { loop = false, volume = 1, delay = 0, playmode } = config;

      if (!track) {
        toast({
          id: "audio-error",
          title: "Audio Error",
          description: `The audio track "${id}" does not exist. Try loading the audio and playing again.`,
          status: "error",
        });

        return undefined;
      }

      const bridgeSound = "playmode" in track ? track : undefined;
      let jsSound = "howl" in track ? track : undefined;

      let isLoaded = true;
      let isPlaying = false;

      if (jsSound) {
        isLoaded = Boolean(jsSound.howl);
        isPlaying = jsSound.howl?.playing() ?? false;

        if (!isLoaded) {
          jsSound = loadTrack(jsSound) as Sound; // TODO: Fix type error here - loadTrack should return a similar type to what it was given
        }
      } else if (bridgeSound) {
        isLoaded = true; // Bridge sounds are always loaded w/ the app
        isPlaying = false; // We currently don't have a way to check if a bridge sound is playing
      }

      if (isMuted) return;
      else if (isLoaded && isPlaying) return undefined;
      else if (isLoaded && !isPlaying) {
        await new Promise((resolve) => setTimeout(resolve, delay));

        if (bridgeSound) {
          bridge?.callHandler(
            HandlerNames.playAudio,
            { track: track.id, playmode: playmode ?? bridgeSound.playmode },
            () => {}
          );
        } else if (jsSound) {
          jsSound?.howl?.play();
          jsSound?.howl?.volume(volume);
          jsSound?.howl?.loop(loop);
        } else {
          throw new Error("Unknown sound type. Cannot play sound.");
        }
      }

      if (track.caption && !track.captionId) {
        openCaption(id);
      }

      return track;
    },

    [isMuted, loadTrack, openCaption, toast, bridge]
  );

  const resumeSound = React.useCallback(
    async (
      id: string,
      config: {
        volume?: number;
        delay?: number;
        loop?: boolean;
        playmode?: HandlerConfigs["playAudio"]["params"]["playmode"];
      }
    ) => {
      const { volume, delay, loop, playmode } = config;

      const track = sounds.current[id];

      if (!track) {
        toast({
          id: "audio-error",
          title: "Audio Error",
          description: `The audio track "${id}" does not exist. Try loading the audio and playing again.`,
          status: "error",
        });
      }

      const bridgeSound = "playmode" in track ? track : undefined;
      let jsSound = "howl" in track ? track : undefined;

      await new Promise((resolve) => {
        const timeout = setTimeout(() => {
          clearTimeout(timeout);
          resolve(true);
        }, delay);
      });

      if (bridgeSound) {
        bridge?.callHandler(
          HandlerNames.resumeAudio,
          { track: track.id, playmode: playmode ?? bridgeSound.playmode },
          () => {}
        );
      } else if (jsSound) {
        jsSound.howl?.play();
        jsSound.howl?.volume(volume ?? jsSound.howl?.volume() ?? 1);
        jsSound.howl?.loop(loop ?? jsSound.howl?.loop() ?? false);
      }

      return track;
    },
    [toast, bridge]
  );

  const pauseSound = React.useCallback(
    (id: string) => {
      const track = sounds.current[id];
      const jsSound = "howl" in track ? track : undefined;

      jsSound?.howl?.pause();

      // TODO: Implement pause for bridge sounds, currently stopping all audio
      bridge?.callHandler(HandlerNames.stopAudio, {}, () => {});
    },

    [bridge]
  );

  const stopSound = React.useCallback(
    (id: string) => {
      const track = sounds.current[id];
      const jsSound = "howl" in track ? track : undefined;

      jsSound?.howl?.stop();

      // TODO: Implement stop for bridge sounds, currently stopping all audio
      bridge?.callHandler(HandlerNames.stopAudio, {}, () => {});
    },
    [bridge]
  );

  React.useLayoutEffect(() => {
    HHowler.autoUnlock = true;
    const groups = Object.values(localSprites);

    groups.map((group) => group.map(loadTrack));
  }, [loadTrack]);

  React.useEffect(() => {
    HHowler.mute(isMuted);

    if (isMuted) {
      bridge?.callHandler(HandlerNames.muteAudio, {}, () => {});
    } else {
      bridge?.callHandler(HandlerNames.unmuteAudio, {}, () => {});
    }
  }, [isMuted, bridge]);

  return {
    sounds,
    loadSound: loadTrack,
    unloadSound: unloadTrack,
    playSound,
    resumeSound,
    pauseSound,
    stopSound,
  };
}
