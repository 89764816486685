import { ComponentSingleStyleConfig } from "@chakra-ui/react";
import { SystemStyleFunction, mode } from "@chakra-ui/theme-tools";

const baseStyle: SystemStyleFunction = (props) => {
  return {
    rounded: "full",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    color: mode("inherit", "inherit")(props),
  };
};

const variantPrimary: SystemStyleFunction = (_props) => {
  return {
    color: "white",
    background: "linear-gradient(189deg, #8866a9 0%, hsl(271, 74%, 28%) 100%)",

    _hover: {
      background: "linear-gradient(189deg, #5B3281 0%, #49137C 100%)",
    },
  };
};

export const Button: ComponentSingleStyleConfig = {
  baseStyle,

  variants: { primary: variantPrimary },
};
