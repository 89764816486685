import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createLogger } from "redux-logger";
import { combineReducers } from "redux";

import { isDevelopment, isProduction } from "@/api/environment";
import { rootApi } from "./root-api";
import { authReducer } from "./slices/AuthStore";
import { audioReducer } from "./slices/AudioStore";

export const rootReducer = combineReducers({
  // We only need to persist the auth store, for the token.
  // If we want to persist other stores, we can add them here.
  auth: persistReducer({ key: "auth", storage, version: 1 }, authReducer),
  audio: audioReducer,

  [rootApi.reducerPath]: rootApi.reducer,
});

const logger = createLogger({
  collapsed: true,
  level: isProduction ? "error" : "",
});

export const rootStore = configureStore({
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware({
      thunk: true,
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER], // redux-persist actions
      },
    }).concat(rootApi.middleware);

    if (isDevelopment) {
      middleware.concat(logger);
    }

    return middleware;
  },
});

export type RootState = ReturnType<typeof rootStore.getState>;
export type RootDispatch = typeof rootStore.dispatch;

export function importStore() {
  let store = rootStore;
  let persistor = persistStore(store);

  return { store, persistor };
}
