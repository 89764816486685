import { ApiRoutes } from "@/api/routeMap";
import { AuthExternalProvider } from "@/features/auth/ExternalProvider";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { rootApi } from "@/services/store/root-api";
import { RootState } from "@/services/store/root-store";

/** Token to define user for the current session */
export type AuthToken = {
  accessToken: string;
  // refreshToken: string;
  // expiresIn: number;
};

/** Auth state for the app. */
type AuthState = {
  token?: AuthToken;
};

// Define the initial state using that type
const initialState = {} as AuthState;

export const authApi = rootApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    create: builder.mutation<{ authentication_token: string }, void>({
      query: () => ({ url: ApiRoutes.users.create, method: "POST" }),
      invalidatesTags: ["Token", "MySlots", "OpenSlots"],
    }),

    createAssociation: builder.mutation<void, AuthExternalProvider>({
      query: (externalProvider) => ({
        url: ApiRoutes.users.createAssociation,
        method: "POST",
        body: externalProvider,
      }),
    }),
  }),
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: () => initialState,

    loginAsUser: (state, action: PayloadAction<AuthToken>) => ({
      token: action.payload,
    }),
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.create.matchFulfilled,
      (_, action) => ({
        token: { accessToken: action.payload.authentication_token },
      })
    );
  },
});

export const { actions: authActions, reducer: authReducer } = authSlice;

export const authSelectors = {
  auth: (state: RootState) => state.auth,
  token: (state: RootState) => state.auth.token,
  isAuthenticated: (state: RootState) => state.auth.token !== undefined,
};
