import * as React from "react";

import { useToast } from "@chakra-ui/react";
import { isProduction } from "@/api/environment";

interface WindowWithInjectedBridge extends Window {
  WKWebViewJavascriptBridge?: any;
  WKWVJBCallbacks?: Array<any>;
  webkit?: any;
}

declare let window: WindowWithInjectedBridge;
function setupWKWebViewJavascriptBridge(callback: (data: any) => void) {
  let setup = () => {
    if (window.WKWebViewJavascriptBridge) {
      return callback(window.WKWebViewJavascriptBridge);
    }

    if (window.WKWVJBCallbacks) {
      return window.WKWVJBCallbacks.push(callback);
    }

    window.WKWVJBCallbacks = [callback];
    window.webkit.messageHandlers.iOS_Native_InjectJavascript.postMessage(null);
  };

  if (
    typeof window === "undefined" ||
    typeof window.webkit === "undefined" ||
    typeof window.webkit.messageHandlers === "undefined"
  ) {
    return;
  }

  if (window.document.readyState == "loading") {
    window.addEventListener("load", setup);
    return;
  }

  setup();
}

export const HandlerNames = {
  applicationReady: "applicationReady",
  requestNotificationPermission: "requestNotificationPermission",
  requestNotificationPermissionToken: "requestNotificationPermissionToken",
  gotNotificationDeviceToken: "gotNotificationDeviceToken",
  requestCameraPermission: "requestCameraPermission",
  performHapticFeedback: "performHapticFeedback",
  gotDeviceShaken: "gotDeviceShaken",
  playAudio: "playAudio",
  resumeAudio: "resumeAudio",
  stopAudio: "stopAudio",
  muteAudio: "muteAudio",
  unmuteAudio: "unmuteAudio",
  setIdleTimer: "setIdleTimer",
  appGotBackgrounded: "appGotBackgrounded",
  appGotForegrounded: "appGotForegrounded",
} as const;

export type HandlerConfigs = {
  [HandlerNames.applicationReady]: {
    params: {};
    callback: () => void;
  };

  [HandlerNames.requestNotificationPermission]: {
    params: {};
    callback: () => void;
  };

  [HandlerNames.requestNotificationPermissionToken]: {
    params: {};
    callback: () => void;
  };

  [HandlerNames.gotNotificationDeviceToken]: {
    params: {};
    callback: (token: string, responseCallback: () => void) => void;
  };

  [HandlerNames.performHapticFeedback]: {
    params: { kind: "success" | "error" | "warning" };
    callback: () => void;
  };

  [HandlerNames.gotDeviceShaken]: {
    params: {};
    callback: () => void;
  };

  [HandlerNames.playAudio]: {
    params: {
      track: "background" | "narration_eshaa";
      /**
       * - `soloAmbient` -> exclusive ambient sound (this will stop all other system playback)
       * - `ambient` -> additional ambient sound (will play over other audio sources)
       * - `playback` -> ignores the silent setting of the phone and will stop all other audio, too
       */
      playmode: "soloAmbient" | "ambient" | "playback";
    };
    callback: () => void;
  };

  [HandlerNames.stopAudio]: {
    params: {};
    callback: () => void;
  };

  [HandlerNames.resumeAudio]: {
    params: {};
    callback: () => void;
  };

  [HandlerNames.muteAudio]: {
    params: {};
    callback: () => void;
  };

  [HandlerNames.unmuteAudio]: {
    params: {};
    callback: () => void;
  };

  [HandlerNames.setIdleTimer]: {
    params: {
      value: boolean;
    };
    callback: () => void;
  };

  [HandlerNames.appGotBackgrounded]: {
    params: {};
    callback: () => void;
  };

  [HandlerNames.appGotForegrounded]: {
    params: {};
    callback: () => void;
  };
};

export type Bridge = {
  callHandler(
    name: keyof HandlerConfigs,
    params: HandlerConfigs[typeof name]["params"],
    callback: HandlerConfigs[typeof name]["callback"]
  ): void;

  registerHandler(
    name: keyof HandlerConfigs,
    callback: HandlerConfigs[typeof name]["callback"]
  ): void;
};

const BridgeContext = React.createContext<{ bridge: null | Bridge }>({
  bridge: null,
});

export const BridgeProvider = ({ children }: { children: React.ReactNode }) => {
  const toast = useToast();
  const [bridge, setBridge] = React.useState<null | Bridge>(null);

  React.useEffect(() => {
    // Setup bridge to Swift app, if we're running inside it.
    setupWKWebViewJavascriptBridge((_bridge) => {
      _bridge.callHandler(HandlerNames.applicationReady, {}, () => {
        if (isProduction) return;

        toast({
          title: "Success",
          description: "Bridge ready",
          variant: "subtle",
          position: "bottom",
          isClosable: true,
        });
      });

      setBridge(_bridge);
    });
  }, [toast]);

  const value = { bridge };

  return (
    <BridgeContext.Provider value={value}>{children}</BridgeContext.Provider>
  );
};

export const useBridge = () => {
  const bridge = React.useContext(BridgeContext);

  return bridge;
};
