import { useToast } from "@chakra-ui/react";
import * as React from "react";

import { APP_NAME } from "@/api/environment";
import { useAudio } from "../audio/AudioContext";
import { HandlerNames, useBridge } from "../bridge/useBridge";
import { GazeEvent, GazeEventHandler } from "./GazeEvent";
import { useControllerActions } from "./useController";

/**
 * Subscription to the controller.
 *
 * Useful for subscribing to global events from the controller.
 *
 *
 * Create another Subscription if you want to subscribe to specific events.
 *
 * @example
 * React.useEffect(() => {
 *   const { id: initId, unsubscribe: onInitUnsubscribe } =
 *     on(
 *       "init",
 *       (data) => console.info("[ControllerSubscription]: init", data),
 *       {
 *         delay: 1000, // Wait 1 second before running the given callback.
 *         once: true, // Only fire once
 *
 *         before: (data) => { // Run before the callback
 *           console.info("[ControllerSubscription]: init -> before", data);
 *         },
 *
 *         after: (data) => { // Run after the callback
 *           console.info("[ControllerSubscription]: init -> after", data);
 *         },
 *       }
 *     );
 *
 *     const initEvent = emit("init", {}); // Emit the event, could be called at any time.
 *     console.log(initEvent)
 *
 *     return () => {
 *       // 1. off(); // removes all listeners for all events (probably not what you want)
 *       // 2. off('init'); // removes all listeners for the 'init' event
 *       // 3. off('init', initId); // removes the listener with id 'initId' for the 'init' event
 *       // 4. onInitUnsubscribe(); // removes the listener this was returned from `on('init', ...)`
 *
 *       off("init");
 *     };
 *   }
 * }, [addTrack, on, off, emit]);
 */
export function ControllerSubscription() {
  const toast = useToast();
  const { playSound } = useAudio();
  const { bridge } = useBridge();

  const { on, emit } = useControllerActions();

  // global handlers
  React.useEffect(() => {
    const onError: GazeEventHandler = (event) => {
      const getErrorMessage = (error: GazeEvent<"error">["data"]) => {
        const defaultMessage = `${APP_NAME} encountered an error.`;

        if (!error) return defaultMessage;
        if (typeof error === "string") return error;
        if (error instanceof Error) return error.message;
        if (typeof error !== "object") return defaultMessage;
        // @ts-expect-error -- see {@link FetchBaseQueryError} from redux-toolkit
        if ("error" in error && typeof error.error === "string") {
          // @ts-expect-error -- see above
          return error.error;
        } else return defaultMessage;
      };

      playSound("error").catch(() => null);
      bridge?.callHandler(
        HandlerNames.performHapticFeedback,
        { kind: "error" },
        () => {}
      );

      toast({
        id: event.ui.toastId,
        title: "Error",
        description: getErrorMessage(event.data),
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
        variant: "left-accent",
      });
    };

    const handlers = [on("error", onError)];

    emit("init");

    return () => {
      handlers.forEach((unsubscribe) => unsubscribe());
    };
  }, [toast, emit, on, playSound, bridge]);

  return null;
}
