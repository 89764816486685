import { ChakraProvider } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { DynamicDevtools } from "@/components/devtools/DynamicDevtools";
import type { AppPropsWithLayout } from "@/components/layouts/NextPageWithLayout";
import { SplashScreen } from "@/components/SplashScreen";
import { AudioProvider } from "@/features/audio/AudioContext";
import { BridgeProvider } from "@/features/bridge/useBridge";
import { ControllerSubscription } from "@/features/controller/ControllerSubscription";
import { ControllerProvider } from "@/features/controller/useController";
import { useServiceWorker } from "@/features/useServiceWorker";
import { importStore } from "@/services/store/root-store";
import { theme } from "@/services/theme/theme";

const AuthLayout = dynamic(() => import("@/components/layouts/AuthLayout"));

export default function GazeApp({ Component, pageProps }: AppPropsWithLayout) {
  const { store, persistor } = importStore();

  useServiceWorker();

  /** get custom layout or set default - see {@link NextPageWithLayout} */
  const getLayout =
    Component.getLayout || ((page) => <AuthLayout>{page}</AuthLayout>);

  return (
    <ChakraProvider theme={theme}>
      <ReduxProvider store={store}>
        <PersistGate persistor={persistor} loading={<SplashScreen />}>
          <BridgeProvider>
            <AudioProvider>
              <ControllerProvider>
                <ControllerSubscription />
                <DynamicDevtools />
                {getLayout(<Component {...pageProps} />)}
              </ControllerProvider>
            </AudioProvider>
          </BridgeProvider>
        </PersistGate>
      </ReduxProvider>
    </ChakraProvider>
  );
}
