import { Sound, Sprite } from "./Sound";

const ROOT_AUDIO_PATH = "/audio";
export const BACKGROUND_AUDIO_PATH = ROOT_AUDIO_PATH + "/background";
export const NARRATION_AUDIO_PATH = ROOT_AUDIO_PATH + "/narration";
export const SOUND_EFFECT_AUDIO_PATH = ROOT_AUDIO_PATH + "/sound-effects";

export const localSprites: Sprite = {
  background: [
    { id: "background", playmode: "soloAmbient" },
    { id: "narration_eshaa", playmode: "soloAmbient" },
  ],

  soundEffects: [
    "Confirmation-accept.m4a",
    "Delete.m4a",
    "Error.m4a",
    "Partner-joined.m4a",
    "pop-out.m4a",
    "Session-start-end.m4a",
  ].map((name) => ({
    id: name // e.g. "Session-start-end.m4a" -> "session_start_end"
      .toLowerCase()
      .replace(/\s/g, "_") // replace spaces with underscores
      .replace(/\.[^/.]+$/, "") // replace the file extension with empty string
      .replace(/[^a-z0-9-]/gi, "_"), // replace all non-alphanumeric characters with underscores

    url: `${SOUND_EFFECT_AUDIO_PATH}/${name}`,
  })),

  narration: Array.from({ length: 66 }, (_, i) => i + 1)
    .map((index) => ({
      id: `narration_${index}`,
      url: `${NARRATION_AUDIO_PATH}/${index}.mp3`,
      caption: `Narration ${index}`,
    }))
    .map((sound) => {
      // Apply captions
      const caption = matchNarrationCaptions(sound);

      return caption ? { ...sound, caption } : sound;
    }),
};

// TODO: Complete captions
function matchNarrationCaptions(sound: Sound): Sound["caption"] {
  switch (sound.id) {
    /** [START] Introduction */
    case "narration_1": {
      return "Hi!";
    }

    case "narration_2": {
      return "Welcome to our prototype eye-gazing tool.";
    }

    case "narration_3": {
      return "We designed this tool to help support everyone";
    }

    case "narration_4": {
      return "in experiencing unconditional love and connection";
    }

    case "narration_5": {
      return "with themselves and others.";
    }

    case "narration_6": {
      return "You are using the version that supports connection with yourself.";
    }

    case "narration_7": {
      return "We'll take you through the process, step-by-step.";
    }

    case "narration_8": {
      return "The whole session will take about 5 minutes.";
    }

    case "narration_9": {
      return "Here we go!";
    }
    /** [END] Introduction */

    default: {
      return undefined;
    }
  }
}
