import { SystemStyleFunction } from "@chakra-ui/theme-tools";

export const globalStyles: SystemStyleFunction = () => {
  return {
    "html, body, #__next, main": { height: "100%" },

    body: {
      position: "relative",
      zIndex: 0,
    },
  };
};
